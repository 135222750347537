<template>
  <v-card>
    <v-card-title>
      {{ moduleTitle }} ({{ addTSBVZ(totalRecords) }})
      <v-spacer></v-spacer>
      <v-btn color="primary" dark @click="folderId = ''; refreshData()">Reset</v-btn>
    </v-card-title>
    <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
    >
    </v-skeleton-loader>
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="3">
          <v-card-title class="pr-0 pl-0">
            Folders
            <v-spacer></v-spacer>
            <a v-if="!addFolder" class="mr-1" title="Refresh Folders" @click="refreshData()">
              <v-icon>mdi-refresh</v-icon>
            </a>
            <a v-if="!addFolder" title="Add New Folder" @click="addFolder = true; addFolderType = 'save'">
              <v-icon>mdi-plus-circle-outline</v-icon>
            </a>
          </v-card-title>
          <div v-if="addFolder">
            <v-autocomplete
                v-model="folderItem.parent_id"
                :items="allFolders"
                item-text="name"
                item-value="id"
                dense
                outlined
                deletable-chips
                chips
                small-chips
                label="Parent Folder"
            >
            </v-autocomplete>
            <v-text-field dense
                          outlined
                          v-model="folderItem.name"
                          maxLength="300"
                          :counter="300"
                          label="Title">
            </v-text-field>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text
                     @click="addFolder = false; addFolderType = ''; folderItem = {name: '', parent_id: ''}"
              >Cancel
              </v-btn
              >
              <v-btn
                  :disabled="!folderItem.name"
                  color="blue darken-1"
                  text
                  @click="createFolder(addFolderType)"
              >
                Save
              </v-btn>
            </v-card-actions>
          </div>
          <v-treeview
              v-if="folders.length > 0"
              class="custom-treeview"
              transition
              open-all
              :items="folders"
              dense
          >
            <template slot="label" slot-scope="{ item }">
              <a @click="getFolderFiles(item)" href="javascript:void(0)">
                <span v-html="item.name"></span>
              </a>
              <v-icon
                  title="Delete Folder"
                  @click="deleteFolder(item)"
                  color="red"
                  dense style="float:right; cursor: pointer">mdi-close
              </v-icon>
              <v-icon
                  @click="folderItem = item; addFolderType = 'edit'; addFolder = true;"
                  title="Rename Folder"
                  dense color="primary" style="float:right; cursor: pointer">mdi-pencil
              </v-icon>
            </template>
          </v-treeview>
        </v-col>
        <v-col cols="12" sm="9">
          <v-row v-if="items.length > 0 && !showSortOverLay">
            <v-col cols="12" sm="6">
              <v-btn color="#00004d" dark
                     class="ml-auto ma-3"
                     style="width:100%"
                     @click="updateFiles">
                Update All
                <v-icon dark x-small class="ml-1">fas fa-save</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn color="primary" dark
                     class="ml-auto ma-3"
                     style="width:100%"
                     @click="refreshData">
                Refresh
                <v-icon dark x-small class="ml-1">fas fa-redo</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="itemSelectedIndexes.length > 0">
            <v-col cols="12" sm="6">
              <v-btn color="#00004d" dark
                     class="ml-auto ma-3"
                     style="width:100%"
                     @click="updateFiles">
                Update All
                <v-icon dark x-small class="ml-1">fas fa-save</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn color="red" dark
                     class="ml-auto ma-3"
                     style="width:100%"
                     @click="deleteFiles">
                Delete Selected Files
                <v-icon dark x-small class="ml-1">fas fa-save</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-checkbox color="#00004d"
                      v-if="items.length > 0"
                      :value="showSortOverLay"
                      @change="showSortChange($event)"
                      label="Change Sort Order Or Delete Files By Clicking"></v-checkbox>
          <v-row>
            <v-col cols="12" sm="4" v-for="(item, index) in items" :key="index"
                   class="gallery-files-col">
              <div class="media-overlay"
                   :ref="'media_' + index"
                   v-if="showSortOverLay"
                   @click="setSortOrder(index, item)"
                   v-html="item.sort_order"
              >
              </div>
              <v-btn color="red" x-small dark @click="deleteFile(item)" class="delete_gallery_file_btn">
                <v-icon dark x-small>fas fa-times</v-icon>
              </v-btn>

              <iframe
                  v-if="
              item.extension === '.doc' ||
              item.extension === '.docm' ||
              item.extension === '.docx' ||
              item.extension === '.txt' ||
              item.extension === '.csv' ||
              item.extension === '.xls' ||
              item.extension === '.xlsx' ||
              item.extension === '.ppt' ||
              item.extension === '.pptx'
              "
                  :src="'https://docs.google.com/gview?url='+appUrl + item.url+'&embedded=true'"></iframe>

              <object v-else
                      :data="item.hasOwnProperty('is_imported') && item.is_imported === 1 ? item.url : appUrl + item.url"
                      style="max-width:100%; max-height:300px;"></object>
              <v-form class="mt-10">
                <v-text-field
                    disabled
                    outlined
                    dense
                    :value="item.title + item.extension"
                    label="File Name"
                ></v-text-field>
                <v-text-field
                    outlined
                    dense
                    :counter="100"
                    maxLength="100"
                    v-model="item.title"
                    label="Edit File Name"
                ></v-text-field>
                <v-text-field
                    outlined
                    dense
                    :counter="10"
                    maxLength="10"
                    @keypress="isNumber($event)"
                    v-model="item.sort_order"
                    label="Sort Order"
                ></v-text-field>
                <v-select
                    outlined
                    dense
                    v-model="item.module_purpose"
                    v-if="moduleTitle === 'Gallery'"
                    clearable
                    label="Select File Type"
                    :items="galleyPurposeFilters"
                    item-text="title"
                    item-value="value"
                ></v-select>
                <v-autocomplete
                    v-model="item.folder_id"
                    :items="allFolders"
                    item-text="name"
                    item-value="id"
                    dense
                    outlined
                    deletable-chips
                    chips
                    small-chips
                    label="Folder"
                >
                </v-autocomplete>
                <v-text-field outlined
                              dense
                              :counter="100" v-model="item.tags"
                              label="Tags"
                ></v-text-field>
                <v-textarea outlined
                            dense
                            :counter="300" v-model="item.description"
                            label="Description"
                ></v-textarea>
              </v-form>
            </v-col>
          </v-row>
          <v-row v-if="items.length > 0 && !showSortOverLay">
            <v-col cols="12" sm="6">
              <v-btn color="#00004d" dark
                     class="ml-auto ma-3"
                     style="width:100%"
                     @click="updateFiles">
                Update All
                <v-icon dark x-small class="ml-1">fas fa-save</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn color="primary" dark
                     class="ml-auto ma-3"
                     style="width:100%"
                     @click="refreshData">
                Refresh
                <v-icon dark x-small class="ml-1">fas fa-redo</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="itemSelectedIndexes.length > 0">
            <v-col cols="12" sm="6">
              <v-btn color="#00004d" dark
                     class="ml-auto ma-3"
                     style="width:100%"
                     @click="updateFiles">
                Update All
                <v-icon dark x-small class="ml-1">fas fa-save</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <v-btn color="red" dark
                     class="ml-auto ma-3"
                     style="width:100%"
                     @click="deleteFiles">
                Delete Selected Files
                <v-icon dark x-small class="ml-1">fas fa-save</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="pagination"
        :server-items-length="totalRecords"
        :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true
                  }"
        :loading="loading"
        v-show="!isLoaded"
        mobile-breakpoint="100"
        class="elevation-0 custom-datatable">
    </v-data-table>
  </v-card>
</template>

<script>
import {mapState} from "vuex";
import {DELETE_FOLDER, UPLOADED_FILES} from "@/core/services/store/uploads.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";

export default {
  name: 'module-uploaded-files',
  props: [
    "moduleItem",
    "moduleTitle",
    "moduleOfFolder",
    "moduleOf",
    "moduleKey"
  ],
  data() {
    return {
      appUrl: this.$store.state.appUrl + '/',
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      headers: [
        {
          text: 'Name',
          value: 'title',
          data: 'title',
          name: 'title',
          filterable: true,
          sortable: true
        },
        {
          text: 'Tags',
          value: 'tags',
          data: 'tags',
          name: 'tags',
          filterable: true,
          sortable: false
        },
        {
          text: 'Description',
          value: 'description',
          data: 'description',
          name: 'description',
          filterable: true,
          sortable: false
        },
        {text: "Sort Order", value: "sort_order", name: "sort_order", filterable: true, sortable: true},
        {text: "Created", value: "created_at", name: "created_at", filterable: true, sortable: true},
        {text: 'Action', value: 'actions', data: null, name: null, filterable: false, sortable: false},
      ],
      items: [],
      folders: [],
      allFolders: [],
      itemSelectedIndexes: [],
      showSortOverLay: false,
      sortInt: 1,
      folderId: '',
      folderItem: {name: '', parent_id: ''},
      addFolder: false,
      addFolderType: ''
    }
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].data +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 3 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "asc" : "desc");
        this.$store.dispatch(UPLOADED_FILES, {
          modelId: this.moduleItem.id,
          folderId: this.folderId,
          model: this.moduleOf,
          modelKey: this.moduleKey,
          moduleTitle: this.moduleTitle,
          moduleOfFolder: this.moduleOfFolder,
          q: query,
          columns: this.columns
        }).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          this.folders = res.records.folders;
          this.allFolders = res.records.all_folders;
          resolve({
            items,
            total
          });
        });
      });
    },
    deleteFile(file) {
      let that = this;
      let path = file.url;
      this.$store
          .dispatch("delete_uploaded_file", {
            file: path,
            model: this.moduleOf,
            delete_model: true,
            type: "old"
          })
          .then(() => {
            that.loadData().then(response => {
              this.items = response.items;
              this.totalRecords = response.total;
            });
          });
    },
    updateFiles() {
      let that = this;
      this.$store
          .dispatch("update_files", {
            files: this.items,
            model: this.moduleOf,
          })
          .then(() => {
            that.loadData().then(response => {
              this.items = response.items;
              this.totalRecords = response.total;
              this.showSortOverLay = false;
              this.sortInt = 1;
              this.itemSelectedIndexes = [];
            });
          });
    },
    deleteFiles() {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to delete all files", clr: 'red', callback: function () {
          that.$store
              .dispatch("delete_uploaded_files", {
                files: that.itemSelectedIndexes,
                model: that.moduleOf,
                delete_model: true,
                type: "old"
              })
              .then(() => {
                that.loadData().then(response => {
                  that.items = response.items;
                  that.totalRecords = response.total;
                });
              });
        }
      });

    },
    createFolder(type) {
      this.folderItem.modelId = this.moduleItem.id
      this.folderItem.moduleOfFolder = this.moduleOfFolder
      this.folderItem.moduleTitle = this.moduleTitle
      this.folderItem.moduleKey = this.moduleKey
      this.folderItem.type = type
      this.$store
          .dispatch("ADD_FOLDER", this.folderItem)
          .then(() => {
            this.loadData().then(response => {
              this.items = response.items;
              this.totalRecords = response.total;
              this.addFolder = false
              this.folderItem = {name: '', parent_id: ''}
            });
          });
    },
    deleteFolder(folder) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?",
        msg: "You want to delete " + folder.name + " and all of its sub folders?",
        clr: 'red',
        callback: function () {
          let id = folder.id;
          this.$store.dispatch(DELETE_FOLDER, {
            'id': id,
            'moduleOfFolder': that.moduleOfFolder,
            'moduleOf': that.moduleOf
          }).then(() => {
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
    showSortChange($event) {
      this.showSortOverLay = !!$event;
      this.sortInt = 1;
      this.itemSelectedIndexes = [];
      if ($event) {
        this.items.findIndex(element => {
          element.sort_order = null;
        });
      } else {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      }
    },
    setSortOrder(index, item) {
      let itemCurrentIndex = item.sort_order;
      if (this.itemSelectedIndexes.length > 0) {
        let fIndex = this.itemSelectedIndexes.find(({sort}) => sort === itemCurrentIndex);
        if (fIndex) {
          let fItem = this.items.find(({id}) => id === item.id);
          if (fItem) {
            fItem.sort_order = null;
            this.itemSelectedIndexes.splice(this.itemSelectedIndexes.indexOf(fIndex), 1)
            this.sortInt--
            this.itemSelectedIndexes.findIndex(element => {
              if (element.sort > itemCurrentIndex) {
                let nItem = this.items.find(({id}) => id === element.item);
                if (nItem) {
                  nItem.sort_order = nItem.sort_order - 1;
                  element.sort = element.sort - 1
                }
              }
            });
          }
        } else {
          let fItem = this.items.find(({id}) => id === item.id);
          if (fItem) {
            fItem.sort_order = this.sortInt++;
            this.itemSelectedIndexes.push({item: fItem.id, sort: fItem.sort_order})
          }
        }
      } else {
        let fItem = this.items.find(({id}) => id === item.id);
        if (fItem) {
          fItem.sort_order = this.sortInt++;
          this.itemSelectedIndexes.push({item: fItem.id, sort: fItem.sort_order})
        }
      }
    },
    getFolderFiles(item) {
      this.folderId = item.id
      this.refreshData()
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.uploads.errors
    })
  }
};
</script>